import JSZip from "jszip";
import { useNavigate } from "react-router-dom";
import { read } from "xlsx";
import { downloadBlob } from "../utils/downloadBlob";
import { useContext, useState } from "react";
import { DataContext } from "../contexts/DataContext";
import { processXlsx } from "../utils/processing";

export type UploadError = {
    msg: string; // The message for the user
    error?: string; // The extended error message
    data?: string[]; // Another extended error message
};

const useUploadFile = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<UploadError | null>(null);
    const navigate = useNavigate();
    const { rawDataReceived, setDataUploaded } = useContext(DataContext);

    const reset = () => {
        setError(null);
        rawDataReceived(null);
    };

    const upload = async (data: FormData) => {
        // when uploading a file, keep track of the original name for the download, remove extention
        const fileName = (data.get("file") as File).name.replace(".xlsx", "");
        setLoading(true);
        setDataUploaded(false);
        try {
            const res = await fetch("/api/upload", {
                method: "POST",
                body: data,
            });

            const contentType = res.headers.get("content-type");
            if (contentType === "application/zip") {
                const ab = await res.arrayBuffer();

                const zip = await JSZip.loadAsync(ab);
                const result_pai = await zip.file("pai_output.xlsx")?.async("arraybuffer");

                if (result_pai !== undefined && result_pai?.byteLength !== 0) {
                    downloadBlob(
                        new Blob([result_pai], {
                            type: "application/vnd.ms-excel",
                        }),
                        `${fileName}-PAI-results.xlsx`,
                    );
                }

                const result = await zip.file("regular_output.xlsx")?.async("arraybuffer");
                if (result !== undefined) {
                    downloadBlob(
                        new Blob([result], {
                            type: "application/vnd.ms-excel",
                        }),
                        `${fileName}-results.xlsx`,
                    );
                    const wb = read(result, { type: "array" });
                    const rawData = processXlsx(wb);

                    if (!rawData) {
                        setError({ msg: "failed to extract raw data from xlsx" });
                    } else {
                        rawDataReceived(rawData);
                    }
                }
                setLoading(false);
                setDataUploaded(true);
                navigate("/dashboard");
            } else if (contentType === "application/json") {
                const data = await res.json();
                if (typeof data.msg === "string") {
                    setError(data as UploadError);
                }
            } else if (res.status >= 500 && res.status <= 599) {
                setError({ msg: `Internal server error` });
            } else {
                // Handle other content types or errors
                console.error(`Unsupported content type: ${contentType}`);
                setError({ msg: `Unsupported content type: ${contentType}` });
            }
        } catch (e) {
            console.error(e);
            setError({ msg: "Error while processing response", error: `Exception ${e}` });
        }
        setLoading(false);
    };
    return { upload, loading, error, reset, setError };
};

export default useUploadFile;
