import React, { ReactNode } from "react";
import { Paper, Stack } from "@mui/material";
import { tableContainerSettings } from "../utils/dashboardSettingsConstants";

interface TableContainerProps {
    children: ReactNode;
}

export const CustomTableContainer: React.FC<TableContainerProps> = ({ children }) => {
    return (
        <Paper sx={tableContainerSettings.style}>
            <Stack direction="row" overflow="auto">
                {children}
            </Stack>
        </Paper>
    );
};
