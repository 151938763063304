import { Stack, Typography, Box } from "@mui/material";
import { t } from "i18next";
import { dashboardSettings } from "../utils/dashboardSettingsConstants";
import { sideBarSettings } from "../utils/sideBarSettings";
import { InvestmentCard } from "./investment/InvestmentCard";
import { SideBarYearCard } from "./SideBarYearCard";
import { useContext, useState } from "react";
import { SideBarAttributionCard } from "./SideBarAttributionCard";
import { MenuContext } from "../App";
import { DataContext } from "../contexts/DataContext";
import { useLocation } from "react-router-dom";

export const SideBar = () => {
    const [openSideBar, setOpenSideBar] = useState(false);
    const { selectedMenu } = useContext(MenuContext);
    const { pathname } = useLocation();
    const { data } = useContext(DataContext);
    const uploaded = !!data;
    return (
        <>
            {selectedMenu !== "upload" && pathname !== "/changelog" && (
                <Stack
                    width="460px"
                    height="100%"
                    boxShadow=" 4px 2px 3px 0px rgba(0,0,0,0.25)"
                    position="absolute"
                    borderRadius={dashboardSettings.borderRadius}
                    top="105px"
                    sx={{
                        backgroundColor: "#FFFFFF",
                        left: openSideBar ? "0px" : "-460px",
                        transition: "all 0.3s linear",
                    }}
                    zIndex={2}
                >
                    <Stack>
                        {uploaded && selectedMenu !== "overview" && (
                            <>
                                <Typography fontSize="16px" mx={4} my={2}>
                                    {t("investmentCard.cardTitle")}
                                </Typography>
                                <Stack {...sideBarSettings.cardProps} sx={sideBarSettings.style}>
                                    <InvestmentCard />
                                </Stack>
                            </>
                        )}
                        <Typography fontSize="16px" mx={4} my={2}>
                            {t("sideBar.filters")}
                        </Typography>
                        <Stack {...sideBarSettings.cardProps} sx={sideBarSettings.style}>
                            <SideBarYearCard />
                        </Stack>
                        <Stack {...sideBarSettings.cardProps} sx={sideBarSettings.style} my={2}>
                            <SideBarAttributionCard />
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            clipPath: "polygon(0 0, 21% 0, 30% 50%, 21% 100%, 0 100%, 0% 50%)",
                            backgroundColor: "#0E364B",
                            cursor: "pointer",
                        }}
                        height="90px"
                        width="100px"
                        position="absolute"
                        right="-100px"
                        top="60px"
                        onClick={() => setOpenSideBar(!openSideBar)}
                    />
                    <Typography
                        fontSize="14px"
                        fontWeight="600"
                        color="#FFFFFF"
                        letterSpacing="3px"
                        position="absolute"
                        right="-44px"
                        top="95px"
                        onClick={() => setOpenSideBar(!openSideBar)}
                        sx={{ transform: "rotate(-90deg)", cursor: "pointer" }}
                    >
                        {t("sideBar.filters")}
                    </Typography>
                </Stack>
            )}
        </>
    );
};
