import { Stack, Typography } from "@mui/material";
import { dashboardSettings } from "../../utils/dashboardSettingsConstants";
import { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SingleTableData } from "../../contexts/DataContext";

import { emissionsColors, employmentColors, filterColors, valueAddedColors } from "../../utils/colorConstants";
import { SelectTableGrouping, TableGrouping } from "./SelectViewGroup";
import { useTranslation } from "react-i18next";
import { SubHeader } from "./SubHeader";
import { sumNumbersWithUndefined } from "../../utils/processing";

const colors = {
    filter: filterColors,
    emission: emissionsColors,
    employment: employmentColors,
    valueAdded: valueAddedColors,
};

type Props = {
    dataType: "dashboard" | "jobs" | "valueadded" | "emissions";
    tableData: SingleTableData | undefined;
};

export const CustomTable = ({ dataType, tableData }: Props) => {
    const { t } = useTranslation();

    const devicePixelRatio = window.devicePixelRatio;

    const [cellWidths, setCellWidths] = useState<number[]>([]);
    const [filteredData, setFilteredData] = useState<SingleTableData>();
    const [sortColumn, setSortColumn] = useState<{
        columnIndex: number;
        ascending: boolean;
    } | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

    const sortedData = useMemo(() => {
        if (sortColumn && filteredData) {
            const sorted = [...filteredData.data].sort((a: any, b: any) => {
                const aValue = a[sortColumn.columnIndex];
                const bValue = b[sortColumn.columnIndex];
                let returnValue = 0;
                if (typeof aValue === "number" && typeof bValue === "number") {
                    returnValue = aValue - bValue;
                } else if (typeof aValue === "number") {
                    returnValue = -1;
                } else if (typeof bValue === "number") {
                    returnValue = 1;
                } else {
                    returnValue = aValue > bValue ? 1 : -1;
                }
                return sortColumn?.ascending ? returnValue : returnValue * -1;
            });
            return {
                columns: filteredData.columns,
                data: sorted,
            };
        } else {
            return filteredData;
        }
    }, [filteredData, sortColumn]);

    const lastColumnGroup = tableData?.columns[tableData.columns.length - 1];
    const connected = lastColumnGroup?.connected;

    // @ts-ignore
    const connectedColor = colors[lastColumnGroup?.color]?.connected;

    // Create an array to store refs for the cells
    const cellRefs = useRef<Array<React.MutableRefObject<HTMLSpanElement | null>>>([]);

    const columnIndexes: number[] = [];

    //Align cells according to device screen ratio
    const calculateMargin = (i: number) => {
        if (devicePixelRatio > 1.3) {
            if (i === 0) {
                return "5px";
            } else if (i === 1) {
                return dataType === "jobs" ? "-35px" : "-45px";
            } else if (i === 2) {
                return dataType === "jobs" ? "10px" : "-4px";
            } else if (i === 3 || i === 4 || i === 6 || i === 8) {
                return "-5px";
            }
        } else if (i <= 1.3) {
            if (i === 0) {
                return "5px";
            } else if (i === 1) {
                return "-15px";
            }
        }
    };

    const roundTableValues = (value: number | undefined, columnIndex: number) => {
        if (value) {
            return value.toFixed(0);
        }
        return "n/a";
    };

    const getColumnNamesForGroup = useCallback(
        (grouping: TableGrouping) => {
            if (tableData?.columns) {
                // default assumes client
                let firstCol = {
                    filter: true,
                    columns: ["client", "totalOutstanding"],
                };
                if (grouping === "activity") {
                    firstCol = {
                        filter: true,
                        columns: ["activity", "totalOutstanding"],
                    };
                }
                if (grouping === "country") {
                    firstCol = {
                        filter: true,
                        columns: ["country", "totalOutstanding"],
                    };
                }
                let originalCols = tableData.columns;
                // replace only the first column definition
                originalCols.splice(0, 1, firstCol);
                return originalCols;
            }
        },
        [tableData?.columns],
    );

    const sumGroup = (group: (number | undefined)[][]): (number | undefined)[] => {
        const groupSum = group.reduce((a, b) => a.map((c, i) => sumNumbersWithUndefined([c, b[i]])));
        return groupSum;
    };

    const makeGroupsAndSums = useCallback((tableDataRows: (string | number | undefined)[][]): (string | number | undefined)[][] => {
        const map = new Map<string, number[][]>();
        tableDataRows.forEach((item) => {
            // key is first index as that will contain the string
            const key = item[0] as string;
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item.slice(1) as number[]]);
            } else {
                collection.push(item.slice(1) as number[]);
            }
        });
        // with the groups made, apply sum to all of them to reduce
        const result = Array.from(map.entries()).map(([key, group]) => [key, ...sumGroup(group)]);
        return result;
    }, []);

    const getTableDataForGroup = useCallback(
        (grouping: TableGrouping): (string | number | undefined)[][] | undefined => {
            if (tableData?.data) {
                if (grouping === "country") {
                    const data = tableData.data.map((row) => {
                        const rowCopy = row.slice();
                        rowCopy.splice(0, 3); // remove index 1, 2, 3
                        rowCopy.unshift(row[1]); // add back in index 2
                        return rowCopy;
                    });
                    return makeGroupsAndSums(data);
                } else if (grouping === "activity") {
                    const data = tableData.data.map((row) => {
                        const rowCopy = row.slice();
                        rowCopy.splice(0, 2); // remove index 0, 1
                        return rowCopy;
                    });
                    return makeGroupsAndSums(data);
                } else {
                    // clients case (default)
                    const data = tableData.data.map((row) => {
                        const rowCopy = row.slice();
                        rowCopy.splice(1, 2); // remove index 1, 2
                        return rowCopy;
                    });
                    return makeGroupsAndSums(data);
                    // to do NOT group but report all rows (backtemp, backperm, finance enabling, power enabling) use this instead:
                    // return data;
                }
            }
            return;
        },
        [makeGroupsAndSums, tableData?.data],
    );

    const handleGroupingSelectionChanged = useCallback(
        (grouping: TableGrouping) => {
            if (tableData) {
                const newColumns = getColumnNamesForGroup(grouping);
                const newData = getTableDataForGroup(grouping);

                const newTableData = {
                    columns: newColumns,
                    data: newData,
                } as unknown as SingleTableData;
                setFilteredData(newTableData);
            }
            setSelectedGroup(grouping);
        },
        [getColumnNamesForGroup, getTableDataForGroup, tableData],
    );

    useEffect(() => {
        // Function to update cell widths according to screen size
        const updateCellWidths = () => {
            const newCellWidths: SetStateAction<number[]> = [];
            cellRefs.current.forEach((cellRef) => {
                if (cellRef && cellRef.current) {
                    const width = cellRef.current.offsetWidth;
                    newCellWidths.push(width);
                }
            });
            setCellWidths(newCellWidths);
        };

        window.addEventListener("resize", updateCellWidths);

        updateCellWidths();

        return () => {
            window.removeEventListener("resize", updateCellWidths);
        };
    }, [filteredData]);

    useEffect(() => {
        handleGroupingSelectionChanged("client");
    }, [handleGroupingSelectionChanged, tableData]);

    if (tableData) {
        return (
            <Stack direction="column" m="auto" my={3} boxSizing="border-box" position="relative">
                <Stack width="100%" sx={{ borderRadius: dashboardSettings.borderRadius }} direction="row">
                    {sortedData?.columns.map((c: any, columnIndex: number) => {
                        let headerColor = "white";
                        // @ts-ignore
                        if (c.connected && c.color && colors[c.color]) {
                            // @ts-ignore
                            headerColor = colors[c.color].connected;
                        }
                        // @ts-ignore
                        else if (c.color && colors[c.color]) {
                            // @ts-ignore
                            headerColor = colors[c.color].header;
                        } else if (c.filter) {
                            headerColor = colors.filter.header;
                        }

                        let subHeaderColor = "white";
                        // @ts-ignore
                        if (c.connected && c.color && colors[c.color]) {
                            // @ts-ignore
                            subHeaderColor = colors[c.color].connected;
                        }
                        // @ts-ignore
                        else if (c.color && colors[c.color]) {
                            // @ts-ignore
                            subHeaderColor = colors[c.color].subheader;
                        } else if (c.filter) {
                            subHeaderColor = colors.filter.subheader;
                        }
                        return (
                            <Stack direction="column" key={columnIndex} borderLeft={dashboardSettings.whiteBorder}>
                                {((connected && !c.filter) || (dataType === "dashboard" && !c.filter)) && (
                                    <Stack
                                        sx={{
                                            backgroundColor: dataType === "dashboard" ? headerColor : connectedColor,
                                        }}
                                        borderRadius={columnIndex === sortedData.columns.length - 1 ? `0px ${dashboardSettings.borderRadius} 0px 0px` : "0px"}
                                        height="20px"
                                    ></Stack>
                                )}
                                {c.filter && (
                                    <Stack
                                        sx={{ backgroundColor: headerColor }}
                                        borderRadius={`${dashboardSettings.borderRadius} 0px 0px 0px`}
                                        color="#FFFFFF"
                                        height="20px"
                                        textAlign="center"
                                        fontSize="12px"
                                    >
                                        {t("table.filter.title")}
                                    </Stack>
                                )}
                                <Stack sx={{ backgroundColor: headerColor, color: "white" }} height="20px" p={1} justifyContent="center" alignItems="center">
                                    {c.header ? (
                                        <Typography fontSize="12px">{t(`table.header.${c.header}`)}</Typography>
                                    ) : (
                                        c.filter && <SelectTableGrouping groupingSelectionChanged={handleGroupingSelectionChanged} />
                                    )}
                                </Stack>
                                <Stack direction="row" width="100%" alignItems="stretch">
                                    {c.columns.map((subHeader: string, subHeaderIndex: number) => {
                                        columnIndexes.push(subHeaderIndex);
                                        return (
                                            <SubHeader
                                                key={subHeaderIndex}
                                                columnIndex={columnIndexes.length - 1}
                                                cellRefs={cellRefs}
                                                subHeaderIndex={subHeaderIndex}
                                                subHeader={subHeader}
                                                subHeaderColor={subHeaderColor}
                                                setSortColumn={setSortColumn}
                                                isSortColumn={columnIndexes.length - 1 === sortColumn?.columnIndex}
                                            />
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
                {sortedData?.data.map((row: any, i: number) => {
                    return (
                        <Stack
                            width={"100%"}
                            key={i}
                            direction="row"
                            alignItems="center"
                            border="solid 1px #ddd"
                            py={1}
                            borderRadius={
                                i === sortedData.data.length - 1 ? `0px  0px ${dashboardSettings.borderRadius} ${dashboardSettings.borderRadius}` : "0px"
                            }
                        >
                            {row.map((cell: string | number, i: number) => {
                                if (cell === undefined) {
                                    cell = "n/a";
                                } else if (cell === 0) {
                                    cell = "0";
                                }
                                if (cell !== "n/a" && cell !== "0" && i === 0) {
                                    if (selectedGroup === "country") {
                                        return (
                                            <Typography
                                                key={i}
                                                fontSize="12px"
                                                width={cellWidths[i]}
                                                textAlign={i === 0 ? "left" : "right"}
                                                ml={calculateMargin(i)}
                                            >
                                                {cell}
                                            </Typography>
                                        );
                                    }
                                }
                                return (
                                    <Typography key={i} fontSize="12px" width={cellWidths[i]} textAlign={i === 0 ? "left" : "right"} ml={calculateMargin(i)}>
                                        {typeof cell === "string" ? cell : t("intlNumber", { val: roundTableValues(cell, i) })}
                                    </Typography>
                                );
                            })}
                        </Stack>
                    );
                })}
            </Stack>
        );
    } else return <Typography>No Data</Typography>;
};
