import { Typography } from "@mui/material";
type Props = {
    tColor: string;
    text: string;
};
export const CardTitle = ({ tColor, text }: Props) => {
    return (
        <Typography color={tColor} fontSize="32px" fontWeight="600">
            {text}
        </Typography>
    );
};
