export const dashboardSettings = {
    containerWidth: "85%",
    borderRadius: "10px",
    grayBorder: "solid 1px #ddd",
    whiteBorder: "solid 1px #FFFFFF",
    cardContainer: "24%",
};
export const resultCardContainerSettings = {
    style: { border: "dashed 1px #A9A9A9", borderRadius: "10px" },
    width: "410px",
    height: "480px",
    padding: 2,
};
export const tableContainerSettings = {
    style: {
        width: dashboardSettings.containerWidth,
        minHeight: "300px",
        m: "auto",
        my: 3,
        backgroundColor: "#FFFFFF",
        borderRadius: dashboardSettings.borderRadius,
    },
};
export const resultCardSettings = {
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
    py: 4,
    spacing: 2,
    width: "100%",
};
export const resultSummarySettings = {
    cardWidth: 300,
    cardHeight: 190,
    cardTitleAndtotalWidth: 115,
    cardTitleAndtotalHeight: 35,
    titleText: "#FFFFFF",
    fontSize: "10px",
};
export const uploadContainerSettings = {
    style: {
        width: "100%",
        height: "240px",
        mt: 5,
        backgroundColor: "#FFFFFF",
        borderRadius: dashboardSettings.borderRadius,
    },
};
